/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('#mailchimp-subscribe-label').text(lang.strings[lang.currentLanguage].subscribe);
        if ($('input[name=EMAIL]').size() > 0) {
          $('input[name=EMAIL]').first().attr('placeholder', lang.strings[lang.currentLanguage].email);
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_post': {
      init: function() {
        if ($('a.print-document').size() > 0) {
          $('a.print-document').on('click', function(e) {
            e.preventDefault();
            window.print();
          });
        }

        if ($('main.main section.widget').size() > 0) {
          $('main.main section.widget').find('div').addClass('hide');
          $('main.main section.widget h3').css({ 'cursor': 'pointer' }).on('click', function(e) {
            if ($('main.main section.widget').find('div').hasClass('hide')) {
              $('main.main section.widget').find('div').removeClass('hide');
            }
            else {
              $('main.main section.widget').find('div').addClass('hide');
            }
          });
        }

        if ($('main.main section#comments').size() > 0) {
          $('main.main section#comments form').addClass('hide');
          $('main.main section#comments h3#reply-title').on('click', function(e) {
            if ($('main.main section#comments form').hasClass('hide')) {
              $('main.main section#comments form').removeClass('hide');
              $('html, body').animate({ scrollTop: $(document).height() }, 1000);
            }
            else {
              $('main.main section#comments form').addClass('hide');
            }
          });
        }

        if ($('a.action-share-facebook, a.action-share-twitter, a.action-share-google-plus').size() > 0) {
          $('a.action-share-facebook, a.action-share-twitter, a.action-share-google-plus').on('click', function(e) {
            e.preventDefault();
            window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            return false;
          });
        }
      }
    },
    'page_template_template_topics': {
      init: function() {
        $('.map').css({
          width: '100%',
          height: $(window).height()
        }).fadeIn('slow');

        $('.map-link').on('click', function(e) {
          e.preventDefault();
          if ($(this).data('url')) {
            window.location.href = $(this).data('url');
          }
        });

        $('.map-topic').on('click', function(e) {
          e.preventDefault();
          if ($(this).data('link')) {
            window.location.href = $(this).data('link');
          }
        });

        $('.map-topic.has-articles').hover(
          function() {
            $('#topic-featured-articles').html('');
            var topicId = $(this).data('id');
            if (featuredArticles[topicId]) {
              var html = '<li>' + featuredArticles[topicId].join('</li><li>') + '</li>';
              $('#topic-featured-articles').html(html);
            }
            $('.topic-hover-section').fadeIn('fast');
          },
          function() {
            $('.topic-hover-section').fadeOut('fast');
          }
        );

        $('div.sidebar').css({
          position: 'absolute',
          left: ($('div.sidebar').width() + 100) * -1,
          top: $('div.sidebar').position().top
        });

        if ($('a#slide-in-menu').size() > 0) {
          $('a#slide-in-menu').on('click', function(e) {
            e.preventDefault();
            if ($('div.sidebar').hasClass('in')) {
              $('div.sidebar').animate({
                left: '-' + $('div.sidebar').width(),
              },
              'fast',
              function() {
                $('div.sidebar').removeClass('in');
              });
            }
            else {
              $('div.sidebar').animate({
                left: 0,
              },
              'fast',
              function() {
                $('div.sidebar').addClass('in');
              });
            }
          });
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
